import React from 'react';
import { Grid, Typography, Link, Breadcrumbs } from '@material-ui/core';
import {useHistory} from "react-router-dom";

export default function NavigationHeader(props) {
    const history = useHistory();
    
    const openPage = (page) => {
        history.replace("/" + page.location);
    }

    return (
        <Grid container style={{maxHeight:"10rem", paddingTop:'1rem', width: "100%", justifyContent: 'center'}}>
            <Grid item>
                <Breadcrumbs>
                    {props.pages.map(page => (
                        <Link key={page.name} color="inherit" href="#" onClick={()=>openPage(page)}>
                            {page.name}
                        </Link>
                    ))}
                    <Typography color="textPrimary">{props.currentPage.name}</Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
    );
}