import React from "react";
import {
    Switch,
    Route,
    useLocation
} from "react-router-dom";
import Landing from "../Landing/landing";
import Projects from "../Projects/projects";
import Writeups from "../Writeups/writeups";
import About from "../About/about";
import Contact from "../Contact/contact";
import NavigationHeader from "../NavigationHeader/navigationHeader";
import { AnimatePresence, motion } from "framer-motion";

import EasyNotes from "../Writeups/EasyNotes/easynotes";
import MemoryManager from "../Writeups/MemoryManager/memorymanager";
import DShell from "../Writeups/dShell/dshell";

export default function RoutingComponent(props) {
    const location = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, [location]);

    const pages = [
        {name: "About", location: "about"},
        {name: "Projects", location: "projects"},
        {name: "Writeups", location: "writeups"},
        {name: "Contact", location: "contact"},
    ]

    const HomePage = {name: "Home", location: ""};

    const writeupPages = [
        {name: "Easy Notes", location: "easynotes"},
        {name: "Memory Manager", location: "memorymanager"},
        {name: "dShell", location: "dshell"}
    ]

    const WriteupsMetadata = [
        {
            title: "dShell",
            location: "dshell",
            description: "Building a UNIX shell from the ground up.",
            date: "January 11th, 2021",
            readTime: "20 min read",
            tags: ["Desktop Application", "Software", "C", "Shell", "UNIX", "Microarchitecture", "Project"]
        },
        {
            title: "Easy Notes",
            location: "easynotes",
            description: "Creating a MERN based task management web application.",
            date: "November 12th, 2020",
            readTime: "6 min read",
            tags: ["Web Application", "AngularJS", "MongoDB", "ExpressJS", "NodeJS", "Javascript", "Project"]
        },
        {
            title: "Memory Manager",
            location: "memorymanager",
            description: "Utilizing the Windows API to create a better task manager in C#.",
            date: "Feburary 6th, 2019",
            readTime: "5 min read",
            tags: ["Desktop Application", "Software", "C#", ".NET", "Windows API", "Project"]
        }
    ]

    return (
        
        <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
                <Route exact path="/">
                    <Landing pages={pages} />
                </Route>
                <Route path="/projects/view/easynotes">
                    <motion.div layout initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.25}} exit={{opacity: 0}} style={{height: '100%'}}> 
                        <NavigationHeader pages={[HomePage, pages[1]]} currentPage={writeupPages[0]}/>
                        <EasyNotes />
                    </motion.div>
                </Route>
                <Route path="/writeups/view/easynotes">
                    <motion.div layout initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.25}} exit={{opacity: 0}} style={{height: '100%'}}> 
                        <NavigationHeader pages={[HomePage, pages[2]]} currentPage={writeupPages[0]}/>
                        <EasyNotes />
                    </motion.div>
                </Route>
                <Route path="/projects/view/memorymanager">
                    <motion.div layout initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.25}} exit={{opacity: 0}} style={{height: '100%'}}> 
                        <NavigationHeader pages={[HomePage, pages[1]]} currentPage={writeupPages[1]}/>
                        <MemoryManager />
                    </motion.div>
                </Route>
                <Route path="/writeups/view/memorymanager">
                    <motion.div layout initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.25}} exit={{opacity: 0}} style={{height: '100%'}}> 
                        <NavigationHeader pages={[HomePage, pages[2]]} currentPage={writeupPages[1]}/>
                        <MemoryManager />
                    </motion.div>
                </Route>
                <Route path="/projects/view/dshell">
                    <motion.div layout initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.25}} exit={{opacity: 0}} style={{height: '100%'}}> 
                        <NavigationHeader pages={[HomePage, pages[1]]} currentPage={writeupPages[2]}/>
                        <DShell />
                    </motion.div>
                </Route>
                <Route path="/writeups/view/dshell">
                    <motion.div layout initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.25}} exit={{opacity: 0}} style={{height: '100%'}}> 
                        <NavigationHeader pages={[HomePage, pages[2]]} currentPage={writeupPages[2]}/>
                        <DShell />
                    </motion.div>
                </Route>
                <Route path="/about">
                    <motion.div layout initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.25}} exit={{opacity: 0}} style={{height: '100%'}}> 
                        <NavigationHeader pages={[HomePage]} currentPage={pages[0]} />
                        <About />
                    </motion.div>
                </Route>
                <Route path="/contact">
                    <motion.div layout initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.25}} exit={{opacity: 0}} style={{height: '100%'}}> 
                        <NavigationHeader pages={[HomePage]} currentPage={pages[3]} />
                        <Contact />
                    </motion.div>
                </Route>
                <Route path="/writeups">
                    <motion.div layout initial={{opacity:0}} animate={{opacity:1}} transition={{duration:.25}} exit={{opacity: 0}} style={{height: '100%'}}> 
                        <NavigationHeader pages={[HomePage]} currentPage={pages[2]} />
                        <Writeups writeups={WriteupsMetadata} />
                    </motion.div>
                </Route>
                <Route path="/projects">
                    <motion.div 
                    layout
                    initial={{opacity:0}}
                    animate={{opacity:1}}
                    transition={{duration:.25}}
                    exit={{opacity: 0}}
                    style={{height: '100%'}}
                    > 
                        <NavigationHeader pages={[HomePage]} currentPage={pages[1]} />
                        <Projects pages={pages} projects={props.projects} />
                    </motion.div>
                </Route>
                <Route path="/">
                    <Landing pages={pages} />
                </Route>
            </Switch>
        </AnimatePresence>
    )
}