import { Grid } from '@material-ui/core';
import React from 'react';
import Header from '../Util/header';
import BodyText from '../Util/bodytext';
import ImageText from '../Util/imagetext';
import MetaData from '../Util/metadata';

import getprocessmethodpng from '../../../Images/Screenshots/memorymanager/getprocessmethod.png';
import processlistpng from '../../../Images/Screenshots/memorymanager/processlist.png';
import hourlygraphpng from '../../../Images/Screenshots/memorymanager/hourlygraph.png';
import snapshotratepng from '../../../Images/Screenshots/memorymanager/snapshotrate.png';
import snapshotgraphpng from '../../../Images/Screenshots/memorymanager/snapshotgraph.png';
import usagealertspng from '../../../Images/Screenshots/memorymanager/usagealerts.png';
import safemodepng from '../../../Images/Screenshots/memorymanager/safemode.png';

function Content(){
    return (
        <Grid container style={{padding: '1rem'}}>
            <Grid item xs={12}>
                <MetaData date="Feburary 6th, 2019" readTime="5 min read" />
            </Grid>
            <Grid item>
                <Header text="Introduction" />
            </Grid>
            <Grid item>
                <BodyText>
                    As the name quite literally implies- this is an application who's intent is to allow the end-user to manage and monitor the memory being utilized by their computer. I've always had a knack for breaking apart concepts and learning what lies behind said concepts. For example, most recently I've had an interest in researching the lower-end functionality of a computer. I've been studying a lot within the realm of lower-end development, with a semi-emphasis in reverse engineering. I've also been picking up on C#, which is a language I have only graced by in the past. So, in an effort to combine both of my current interests; I thought it fun to essentially recreate the task manager from the ground up- this time with an emphasis on background analysis.    
                </BodyText>
            </Grid>
            <Grid item>
                <Header text="Body" />
            </Grid>
            <Grid item>
                <ImageText src={getprocessmethodpng}>
                    As the first point of reference- I needed to figure out how specifically to interact with the various processes on the system. More specifically, how to get ahold of the currently opened processes, and how much memory they were using. In my research, I came across a very handy .NET method under the System.Diagnostics namespace- specifically Process.GetProcesses. From this, I was able to get an array of the Process type, all of which are currently active on the machine.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={processlistpng}>
                    My next hurdle was figuring out specifically what I needed to see. While it may seem self-explanatory, I more specifically needed to figure out what memory I wanted to inspect. See, it turns out there's two different types of memory allocated on a machine; Physical Memory and Virtual Memory. Then there's all sorts of subsets, such as; Paged Memory, Non-paged memory, Private memory, and so forth. With my original goal in mind, I decided to side with Physical memory- being as that's what task manager uses primarily in it's display.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={hourlygraphpng}>
                    From there, it was only a matter of putting it all together and adding additional features for customization. After experimenting with various graphs, I decided to go with the pie graph. It seemed to be the most suitable for my intentions, and also displayed the information I wanted to display in a much more clean fashion than the other graphs.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={snapshotratepng}>
                    So we all know the window's task manager to be (quite) resource intensive. To fix that, I decided to opt for a more background appropriate method- snapshots. I found that just taking snapshots of the applications once every 20 seconds to be far less intensive, while also maintaining consistent data. Although, the program does allow for the end-user to change the snapshot rate to any value they desire.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={snapshotgraphpng}>
                    While an overall graph of your hourly usage may suit most people looking to get a general idea of their memory usage, individual snapshots are also able to be saved and inspected individually- for those looking to take a deeper inspection across various time frames.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={usagealertspng}>
                    Another usage for the application could be to figure out when applications may be spiking randomly- so I also incorporated a Usage Alerts setting. One that alerts the end-user when any program suddenly spikes in its memory usage.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={safemodepng}>
                    In an effort to further optimize the program, while at the same time slightly losing consistency- I implemented a Safe Mode feature. A feature that cleans up unnecessary data being stored, and makes snapshots less chunky. Although being as this cuts some corners, it does lose an element of precision.
                </ImageText>
            </Grid>
            <Grid item>
                <Header text="Conclusion" />
            </Grid>
            <Grid item>
                <BodyText>
                    Overall, I loved working on this project. Looking back at it, I'm not very happy with how the UI turned out, and looking at the source- from where I am today as a developer- I know I could definitely refine it in more ways than one. But at the time of making it, and for where I was in my development career- I'm quite proud of how it turned out. It was a fun concept, and even more fun implementing it. I may come back in the future and clean it up a bit though.
                </BodyText>
            </Grid>
        </Grid>
    )
}

export default function MemoryManager(){
    return (
        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
            <Grid>
                <Content /> 
            </Grid>
        </Grid>
    )
}