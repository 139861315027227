import { Grid, Link } from '@material-ui/core';
import React from 'react';
import Header from '../Util/header';
import BodyText from '../Util/bodytext';
import ImageText from '../Util/imagetext';
import MetaData from '../Util/metadata';

import basic_lifecycle from '../../../Images/Screenshots/dshell/basic_lifecycle.png';
import conclusion from '../../../Images/Screenshots/dshell/conclusion.png';
import executor_duplicate_process from '../../../Images/Screenshots/dshell/executor_duplicate_process.png';
import executor_file_handle_change_macros from '../../../Images/Screenshots/dshell/executor_file_handle_change_macros.png';
import executor_file_handle_example from '../../../Images/Screenshots/dshell/executor_file_handle_example.png';
import executor_pipe_final from '../../../Images/Screenshots/dshell/executor_pipe_final.png';
import executor_redirect from '../../../Images/Screenshots/dshell/executor_redirect.png';
import parser_bad_sample from '../../../Images/Screenshots/dshell/parser_bad_sample.png';
import parser_beforeandafter from '../../../Images/Screenshots/dshell/parser_beforeandafter.png';
import parser_fixed_sample from '../../../Images/Screenshots/dshell/parser_fixed_sample.png';
import parser_parsetype from '../../../Images/Screenshots/dshell/parser_parsetype.png';
import tokenizer from '../../../Images/Screenshots/dshell/tokenizer.png';
import tokenizer_bad_sample from '../../../Images/Screenshots/dshell/tokenizer_bad_sample.png';
import tokenizer_final_overview from '../../../Images/Screenshots/dshell/tokenizer_final_overview.png';
import tokenizer_sample from '../../../Images/Screenshots/dshell/tokenizer_sample.png';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeText = {
0: 
`typedef struct{
    COMMAND_TYPE type;
    int argument_length;
    int memory_size;
    void* left;
    void* right;
    const char** arguments;
}s_command;`,
1:
`typedef enum {
    COMMAND = 0,
    STRING = 1,
    ENDCOMMAND = 2,
    PIPE = 3,
    SUBSHELL = 4,
    SUBSHELL_START = 5,
    SUBSHELL_END = 6,
    NOT = 7,
    AND = 8,
    OR = 9,
    REDIRECT_TO = 10,
    REDIRECT_TO_OVERWRITE = 11
} COMMAND_TYPE;`,
2:
`s_command* parse(s_command** commands){
    condense_subshells(commands);
    parse_not(commands);

    parse_type(commands, PIPE);
    parse_type(commands, REDIRECT_TO);
    parse_type(commands, REDIRECT_TO_OVERWRITE);
    parse_logicals(commands);
    parse_type(commands, ENDCOMMAND);
    parse_leftovers(commands);

    return commands[0];
}`,
3:
`int condense_subshells(s_command** commands){
    int index = 0;
    s_command* current = commands[index];

    while(current != NULL){
        if(current->type == SUBSHELL_START){
            int end = condense_subshells(&commands[index + 1]);

            end += index + 1;
            commands[end] = NULL;

            current->left = parse(&commands[index + 1]);
            commands[index + 1] = NULL;

            current->type = SUBSHELL;

            shift_to_left(&commands[index + 1], end - index);
        }else if(current->type == SUBSHELL_END) return index;

        index += 1;
        current = commands[index];
    }
    return -1;
}`,
4:
`EXIT_STATUS execute(s_command* command){
    if(command == NULL || command->type == STRING) return EXIT_SUCCESS;
    return (command->type == COMMAND) ? execute_command(command) : execute_special(command);
}`,
5:
`EXIT_STATUS handle_subshell(s_command* command){
    EXIT_STATUS status;
    int pid = fork();

    if(pid){
        do {
            waitpid(pid, &status, WUNTRACED);
        }while(!WIFEXITED(status) && !WIFSIGNALED(status));
    }else{
        execute(command->left);
        exit(EXIT_SUCCESS);
    }

    return status;
}`,
6:
`_Noreturn void lifecycle(void){
    while(true){
        prompt("$");

        char* line = read_line(stdin);
        s_command** commands = tokenizer(line);
        s_command* command_tree = parse(commands);
        execute(command_tree);
    }
}`
}

function CodeBlock(props){
    return (
        <Grid item style={{maxWidth: '90vw', width:'100%', paddingBottom: '.5rem', fontSize: '1rem', font: "menu"}}>
            <SyntaxHighlighter language="c" style={atomDark}>
                {props.text}
            </SyntaxHighlighter>
        </Grid>
    )
}

function Content(){
    return (
        <Grid container style={{padding: '1rem'}}>
            <Grid item xs={12}>
                <MetaData date="January 11th, 2021" readTime="20 min read" />
            </Grid>
            <Grid item>
                <Header text="Introduction" />
            </Grid>
            <Grid item>
                <BodyText>
                    Coming out of my internship at Google, where I applied a great deal of planning and design work within the realm of Kotlin development- I wanted to take a break from all the abstraction and work on a project that would be a little closer to the metal. What better way than to design a shell from the ground up!
                </BodyText>
            </Grid>
            <Grid item>
                <Header text="Body" />
            </Grid>
            <Grid item>
                <BodyText>
                    For those unfamiliar, a shell is just a program that takes user input and facilitates it through to the operating system. In my case, that would be UNIX. At a basic level, a shell lifecycle consists of: wait for input, process input. But things get a bit more complicated when you want to start allowing dynamic entries with grammar. In this write-up, I'll walk you through my journey and implementation in the development of my shell- dShell. The shell is open-sourced on GitHub, which you can find either on my <Link href="https://daymxn.com/projects">projects page</Link>, or you can just click <Link href="https://github.com/daymxn/dShell">here</Link>.
                </BodyText>
            </Grid>
            <Grid item>
                <BodyText>
                    Although, before we can make a shell, we need to understand how a shell works. We already talked a little bit about the shell lifecycle, but let's dive a little deeper into what one would have to do for more complex grammar.
                </BodyText>
            </Grid>
            <Grid item>
                <ImageText src={basic_lifecycle}>
                    First, you receive input from the user. Oftentimes this is via the command line, but it could come from anywhere. You then need to decide what the input means. Does the user want to start a program, or is this an input for a program? You should also validate the input and restructure it in a way that aligns with the grammar. And finally, you execute the input.
                </ImageText>
            </Grid>
            <Grid item>
                <BodyText>
                    As it turns out, this is a common problem- even outside of shell development. Deciding what the input means is referred to as Lexical Analysis, or "tokenizing" the input. Validating the tokens and restructuring them according to grammar is referred to as Syntax Analysis, or "parsing" the input. Theoretically, you could parse the input inline- but such an implementation would require constant looking-ahead, or keeping track of a complex state machine. So all in all, it's much easier to just split the process up. Your time complexity won't suffer as much as you may be thinking either, especially in a language like C. At the end of the day, it'll still be O(N).    
                </BodyText>
            </Grid>
            <Grid item>
                <BodyText>
                    Those already familiar with Lexical Analysis and Syntax Analysis have probably also heard of (or played with) the go-to programs Flex/Bison (or if you're an old-head, Lex and Yacc- which are bundled by default in most modern-day UNIX systems). These programs provide a way for engineers to define a pre-set grammar and batch the tedious work to the program instead of handling it themselves. There's just one little problem though... I like doing it myself :). So we'll be engineering a Lexer & Parser from the ground up, with this shell specifically in mind!
                </BodyText>
            </Grid>
            <Grid item>
                <Header text="Lexical Analysis" />
            </Grid>

            <Grid item>
                <BodyText>
                    Before we get into the lexer though, we'll need to create some form of representation of what a command looks like. I opted for a command struct:
                </BodyText>
            </Grid>
            <CodeBlock text={CodeText[0]} />
            <Grid item>
                <BodyText>
                    We’ll get more into the properties later, but the important part is we have a way to not only track the arguments of a command- but also define its type:
                </BodyText>
            </Grid>
            <CodeBlock text={CodeText[1]} />
            <Grid item>
                <BodyText>
                    We can separate commands that should be executed, with commands that are just placeholders for special symbols. A quick lesson for those unfamiliar with typical shell grammar, the enums you see above represent various special symbols on the command line- where each symbol represents a unique change of state. For example, when two commands are separated by AND, the second command is only executed if the first command exits successfully. You can learn more about the grammar <Link href="https://github.com/daymxn/dShell#builtins">here</Link>.
                </BodyText>
            </Grid>
            <Grid item>
                <ImageText src={tokenizer}>
                    Now we can get into designing the lexer! Ignoring the specifics of the grammar, our goal is to separate commands from symbols. To do that, we’ll loop through the provided input and apply some methodical thinking.
                </ImageText>
            </Grid>
            <Grid item>
                <BodyText>
                    If that looks intimidating to you, don’t worry, we’re going to break it down. We’ll loop through the input, and for each character we’ll apply a sequence of checks. First, some key states that take priority will be checked. If the character is a quote, then the quoted state should be toggled. When under the quoted state, all input is processed as a string literal. 
                </BodyText>
            </Grid>
            <Grid item>
                <BodyText>
                    Next, if the character is a newline or EOF (short for End Of File, a symbol added to text input to signal the end), then we’re at the end of our input. And as such, we should clean up and finish.
                </BodyText>
            </Grid>
            <Grid item>
                <BodyText>
                    If the character is instead just a space, then we will either add the space to our current string (the command we’re building) or we’ll add the string as an argument. A space separates two arguments on the command line (unless under a string literal, of course).
                </BodyText>
            </Grid>
            <Grid item>
                <BodyText>
                    Finally, if the character doesn’t meet any of the key states, we’ll move into general processing. If we’re under a quoted state, we’ll just add the character to the current string. Otherwise, we’ll make a quick check to see if the character is part of a special symbol that needs separate processing (similar to our key states), and batch the character as needed.
                </BodyText>
            </Grid>
            <Grid item>
                <ImageText src={tokenizer_sample}>
                    After recursively looping through the input, our tokenizer will eventually return a completed array of tokens (in chronological order).
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={tokenizer_bad_sample}>
                    Note that every command is separated by a special symbol. This is a standard of the grammar. The only way to be able to differentiate between two commands is special symbols, otherwise the second command would just be interpreted as the arguments of the first command. And if you re-examine the flow chart before the one above, you may have noticed that the only place we seem to be adding the token to the list of tokens is on newline and EOF. If that were the case, then wouldn’t we just have one long command with all the other commands as arguments? 
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={tokenizer_final_overview}>
                    We absolutely would, if that were really the case. But what’s actually going on is that when we make a call to “process special symbol” each symbol will apply some change to the state. A common change to the state that is predominant across all symbols is finishing the current token and adding it to tokens. So our tokens list is actually (mostly) being populated through special symbols.
                </ImageText>
            </Grid>

            <Grid item>
                <Header text="Syntax Analysis" />
            </Grid>
            <Grid item>
                <ImageText src={parser_beforeandafter}>
                    Clarifying our goals once again, we want to design a parser that takes in an array of tokens (commands & symbols) and arranges them according to the grammar. For example, with our current array of tokens:
                </ImageText>
            </Grid>
            <Grid item>
                <BodyText>
                    We create a tree of tokens, such that they can be evaluated in the proper order of necessity (according to the grammar, and assuming you traverse the tree pre-order). We’ll get more into the specifics of traversing the tree when we go over designing an executor for the shell, but for now just picture that we want a tree that gets evaluated pre-order for execution.
                </BodyText>
            </Grid>
            <Grid item>
                <ImageText src={parser_bad_sample}>
                    Designing a parser is commonly looked at as the most difficult part of shell creation, and for good reason. A command structure is not typically linear, and usually involves multiple subshells, pipes, and redirects. For those confused, I’ll draw an example:
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={parser_fixed_sample}>
                    Does anything seem out of place here? If we evaluate this tree pre-order then ls gets executed, then the pipe. But in order for the pipe to do its job it needs to store the output of ls before it gets executed. Otherwise, the output of ls will be streamed to stdout- a stream that could have outputs from previous commands already. If we take that into consideration during construction of our tree, we end up with something like this:
                </ImageText>
            </Grid>
            <Grid item>
                <BodyText>
                    The pipe will now get evaluated before the ls, and can now properly capture the output from the command before it goes to stdout.
                </BodyText>
            </Grid>
            <Grid item>
                <BodyText>
                    Now that we’ve established why some commands come with a higher priority over others, the following might make sense to you:
                </BodyText>
            </Grid>
            <CodeBlock text={CodeText[2]} />
            <Grid item>
                <ImageText src={parser_parsetype}>
                    This is the general process of our parser. As you can see, some symbols take priority over others. The order is very important here, and is something that you come to the conclusion of through theorizing edge cases (I’ll save you that headache). Although, I will walk you through the more important aspects of the procedure. For starters, you may have noticed a single method repeating more than the others. Our parse_type function takes any COMMAND_TYPE and will loop through the tokens looking for said symbol. Once a symbol is found, we’ll assign the left and right child to the symbol, shift the array and continue through the tokens. A visual representation can be seen below:
                </ImageText>
            </Grid>
            <Grid item>
                <BodyText>
                    This will work for the majority of symbols, although some will require their own special procedures. One of interest is the first function we call- condense_subshells. Subshells are similar to brackets in math, where the sub-expression needs to be grouped separately from the rest of the equation (or in this case, the rest of the tokens). As such, all the commands within a subshell are going to need to be evaluated within their own scope. And whenever we’re talking about creating a fresh scope of an identical problem, it almost always makes sense to implement some form of dynamic programming or recursion. Also keep in mind that subshells can have their own subshells.
                </BodyText>
            </Grid>
            <CodeBlock text={CodeText[3]} />
            <Grid item>
                <BodyText>
                    We’ll loop through the tokens looking for a SUBSHELL_START. Anytime we find one we’ll spawn a new condense_subshell from that point forward to look for a SUBSHELL_END. The key thing to look at here is that since we’re spawning another condense_subshell, any nested SUBSHELL_START will also be picked up. This is the recursive nature of our solution, and keeps our process modular and intuitive. Once a SUBSHELL_END gets picked up, the child process will return the index and the parent will continue. We talked earlier about how the commands within a subshell have their own unique scope, and you’ll see that come into play here. We’ll spawn a new parse call from SUBSHELL_START to SUBSHELL_END, assign it to the subshell command, change the command type, and clean up after ourselves. Essentially, all subshells get their children completely parsed before anything else.
                </BodyText>
            </Grid>
            <Grid item>
                <BodyText>
                    The parse_logicals function isn’t super important, but lets go over it real quick. Logicals get evaluated as equals, which means whatever comes first chronologically gets evaluated first. If we were to use parse_type with all the logical symbols, instead of making a unique method, we would have symbols being prioritized over others. So we split logicals into their own method, which is exactly the same as parse_type- except that it looks for all logical symbols in one pass.
                </BodyText>
            </Grid>
            <Grid item>
                <Header text="Executor" />
            </Grid>
            <Grid item>
                <BodyText>
                    After the lexical analysis and syntax analysis have built a command tree, executing the tree is actually fairly simple. Although, there are a couple interesting specifics with symbols that some seem to get stumbled upon, which I’ll go over.
                </BodyText>
            </Grid>
            <CodeBlock text={CodeText[4]} />
            <Grid item>
                <BodyText>
                    We traverse the command tree pre-order, check if the command is a special symbol, execute the command, and return the exit status. This simple and modular process allows us to draw up a recursive solution as well, which is almost always possible when working with trees.
                </BodyText>
            </Grid>
            <Grid item>
                <BodyText>
                    Most of the symbols are fairly straightforward implementation wise, although in my research I found that people often struggled with a couple in particular: pipes, subshells, and redirects. Let's go over the specific implementations for each of these.
                </BodyText>
            </Grid>
            <Grid item>
                <ImageText src={executor_file_handle_example}>
                    Pipes connect the output from one command to the input of the following command. The main confusion I see here is from people misunderstanding how file descriptors and forking work together. Each process has a unique table of file descriptors, which gets handled by the kernel. A file descriptor is essentially a file handle, we have a unique number that correlates with an index on our file descriptor table. The file object pointed to at this index could be completely different from the same index within another process- this is how two processes can open separate handles to the same file.
                </ImageText>
            </Grid>
            <Grid item>
                <BodyText>
                    *This is an oversimplification of what actually happens, our file descriptor table is not actually index values to the kernel
                </BodyText>
            </Grid>
            <Grid item>
                <ImageText src={executor_file_handle_change_macros}>
                    One very important standard though, the first 3 file descriptors for any given process are “reserved”; Standard Input, Standard Output, and Standard Error. You will see these commonly referred to under macros as STDIN, STDOUT and STDERR respectively. All kernel methods read through these macros by default, but they are not strictly enforced. What that means is that we can assign any file descriptor we want to any of STDIN, STDOUT, or STDERR. 
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={executor_duplicate_process}>
                    This really comes in handy when we want to temporarily spawn a new process with different descriptors.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={executor_pipe_final}>
                    Since forking a process spawns a new process and duplicates the file descriptors of the parent, we can save the old file descriptors and set up temp descriptors for capturing of data. If it helps, you can think of it as a Man in The Middle Attack, where the source is the left command, the target as the right command, and the pipe as the attacker.
                </ImageText>
            </Grid>
            <Grid item>
                <BodyText>
                    Subshells are another symbol people often struggle with. To be fair though, I feel most of the struggles people have with subshells is that they don’t have an effective or thought-out parser. With a proper parser, handling subshells is actually very straight-forward.
                </BodyText>
            </Grid>
            <CodeBlock text={CodeText[5]} />
            <Grid item>
                <BodyText>
                    We fork to spawn a sub-process, and tell the subprocess to start at the subshell node. The child then just starts over as if it was their first time running the executor, while the parent waits for a response. You may be starting to see how all that parsing came in handy.
                </BodyText>
            </Grid>
            <Grid item>
                <ImageText src={executor_redirect}>
                    Redirects are in a similar boat to pipes, and are fairly easy to implement once you wrap your head around how to implement pipes. The only difference is the implementation detail of strings. Thankfully, we already implemented a type to keep track of string literals.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={conclusion}>
                    And once we’ve got the executor up and running, it's just a matter of taking a step back and looking at our baby.
                </ImageText>
            </Grid>
            <CodeBlock text={CodeText[6]} />

            <Grid item>
                <Header text="Conclusion" />
            </Grid>
            <Grid item>
                <BodyText>
                    This was one of the more fun projects I’ve worked on in awhile. Primarily because it gave me the opportunity to not only learn a lot more about the Linux kernel, but it also forced me to get a much better understanding of how shells and CLIs work behind the scenes. One thing that did feel weird through-out this project though, was not writing tests. While there are numerous pretty great C Testing libraries, none of them truly had what I was looking for. Who knows, maybe that should be my next project.
                </BodyText>
            </Grid>
        </Grid>
        
    )
}

export default function DShell(){
    return (
        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
            <Grid item>
                <Content /> 
            </Grid>
        </Grid>
    )
}