import React from 'react';
import {Grid, Typography, Card, CardActionArea, SvgIcon, Chip} from '@material-ui/core';
import {EventNote as dateImage, Schedule as readImage} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';


function IconWithText(props){
    return (
        <Grid container>
            <Grid item>
                <SvgIcon component={props.src} style={{fontSize: props.fontSize, color: '#fff', paddingRight: '.25rem'}}/>
            </Grid>
            <Grid item>
                <Typography style={{fontSize: props.fontSize}} color="textSecondary">
                    {props.children}
                </Typography>
            </Grid>
        </Grid>

    )
}

function MetaData(props){
    return (
        <Grid container style={{paddingBottom: '1rem'}}>
            <Grid item xs={12}>
                <IconWithText fontSize={'.8rem'} src={dateImage}>{props.date}</IconWithText>
            </Grid>
            <Grid item xs={12}>
                <IconWithText fontSize={'.8rem'} src={readImage}>{props.readTime}</IconWithText>
            </Grid>
        </Grid>
    )
}

function Writeup(props) {
    const history = useHistory();

    const handleClick = () => {
        history.push('/writeups/view/'+props.location);        
    }
    return (
        <Card variant="outlined" style={{margin: '1rem'}}>
            <CardActionArea onClick={handleClick} style={{padding: '.5rem'}}> 
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h6">{props.title}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{props.description}</Typography>
                    </Grid>
                    <Grid item>
                        <MetaData date={props.date} readTime={props.readTime} />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            {(props.tags)?props.tags.map(tag=>(
                                <Grid key={tag} item>
                                    <Chip size="small" label={tag} />
                                </Grid>
                            )):null}
                        </Grid>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    )
}

function Content(props) {
    return (
        <Grid container>
            {(props.writeups)?props.writeups.map(writeup=>(
                <Grid item xs={12} key={writeup.location}>
                    <Writeup {...writeup} />
                </Grid>
            )):null}
        </Grid>
    )
}

export default function Writeups(props){
    return (
        <Content {...props} />
    )
}