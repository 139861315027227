import React from 'react';
import {Grid, IconButton} from '@material-ui/core';
import {Email as EmailIcon, LinkedIn as LinkedInIcon, GitHub as GitHubIcon} from '@material-ui/icons';
import {motion} from 'framer-motion';

function Content(){
    return (
        <Grid container style={{justifyContent: 'center', alignContent: 'center', height: '100%', padding: '1rem', maxWidth: '600px'}}>
            <Grid item xs={4}>
                <motion.button style={{borderStyle: "none", backgroundColor: "transparent"}} 
                    whileHover={{
                        scale:1.1, 
                        transition: {duration: .25}}}
                >
                    <IconButton href="mailto:daymon@daymxn.com" variant="outlined"><EmailIcon style={{width: '100%', height: '100%'}}/></IconButton>
                </motion.button>
            </Grid>
            <Grid item xs={4}>
                <motion.button style={{borderStyle: "none", backgroundColor: "transparent"}} 
                    whileHover={{
                        scale:1.1, 
                        transition: {duration: .25}}}
                >
                <IconButton href="https://www.linkedin.com/in/daymxn" variant="outlined"><LinkedInIcon style={{width: '100%', height: '100%'}}/></IconButton>
                </motion.button>
            </Grid>
            <Grid item xs={4}>
                <motion.button style={{borderStyle: "none", backgroundColor: "transparent"}} 
                    whileHover={{
                        scale:1.1, 
                        transition: {duration: .25}}}
                >
                <IconButton href="https://github.com/daymxn" variant="outlined"><GitHubIcon style={{width: '100%', height: '100%'}}/></IconButton>
                </motion.button>
            </Grid>
        </Grid>
    )
}

export default function Contact(){
    return (
        <Grid container style={{justifyContent: 'center', alignContent: 'center', height: '95%'}}>
            <Content />
        </Grid>
    )
}