import {ReactComponent as ReactJS} from './Images/Stacks/React.svg'
import {ReactComponent as Javascript} from './Images/Stacks/Javascript.svg';
import {ReactComponent as NodeJS} from './Images/Stacks/NodeJS.svg';
import {ReactComponent as ExpressJS} from './Images/Stacks/ExpressJS.svg';
import {ReactComponent as MongoDB} from './Images/Stacks/MongoDB.svg';
import {ReactComponent as AngularJS} from './Images/Stacks/AngularJS.svg';
import {ReactComponent as CSharp} from './Images/Stacks/C#.svg';
import {ReactComponent as C} from './Images/Stacks/C.svg';
import {ReactComponent as CPlusPlus} from './Images/Stacks/C++.svg';
import {ReactComponent as Python} from './Images/Stacks/Python.svg';
import {ReactComponent as DotNET} from './Images/Stacks/DotNET.svg';
import {ReactComponent as Kotlin} from './Images/Stacks/Kotlin.svg';
import {ReactComponent as HTML5} from './Images/Stacks/HTML5.svg';
import {ReactComponent as Gradle} from './Images/Stacks/Gradle.svg';

export const Projects = [
    {
        name: "dShell",
        learnLocation: "dshell",
        sourceLocation:"https://github.com/daymxn/dShell",
        type: "C Application",
        date: "December 21st, 2021",
        description: "Command-Line-Interface for interacting with the UNIX kernel from ring 3, with support for complex grammar such as; Builtins, Subshells, Redirection, Logical Operators, Pipes, and more.",
        stacks: [C]
    },
    {
        name: "EasyNotes",
        learnLocation: "easynotes",
        demoLocation:"https://easynotes.daymxn.com/",
        type: "Web Application",
        date: "November 12th, 2020",
        description: "User-friendly web application for managing tasks for a group of people, with various customizable functions. Targeted specifically at teams of people.",
        stacks: [Javascript, NodeJS,ExpressJS, MongoDB, AngularJS]
    },
    {
        name: "Memory Manager",
        learnLocation: "memorymanager",
        sourceLocation:"https://github.com/daymxn/Memory-Manager",
        type: "C# Application",
        date: "Feburary 6th, 2019",
        description: "Simple and clean memory manager for monitoring whats going on behind the scenes of your computer.",
        stacks: [CSharp, DotNET]
    },
    {
        name: "Force One",
        demoLocation: "https://force-one-client.uc.r.appspot.com/",
        type: "Web Application",
        date: "May 17th, 2021",
        description: "A streaming web application for a client- in which the client can stream Basketball games to end-users via an online pay-per-view payment system. The end-users can watch the games live if they purchase access, and watch previous games as well.",
        stacks: [Javascript, ReactJS, NodeJS, ExpressJS, MongoDB]
    },
    {
        name: "ECS",
        type: "C++ Application",
        date: "March 1st, 2020",
        description: "Encrypted Chat System (ECS) is an end-to-end asymmetric encrypted chat service, similar to WhatsApp and Signal.",
        stacks: [CPlusPlus]
    },
    {
        name: "pyEncode",
        type: "Python Application",
        date: "October 16th, 2020",
        description: "An easy-to-use image encoding system in Python, where the end-user can encode their own messages into a selected image.",
        stacks: [Python]
    },
    {
        name: "dChat",
        documentationLocation: "https://github.com/daymxn/dChat#documentation",
        sourceLocation:"https://github.com/daymxn/dChat",
        type: "Kotlin Application",
        date: "April 26th, 2022",
        description: "Desktop messaging platform, developed and designed completely in Kotlin. Fully complete backend service, with end-to-end documentation and business layer testing.",
        stacks: [Kotlin, Gradle]
    },
    {
        name: "kHTML",
        documentationLocation: "https://khtml.daymxn.com/",
        sourceLocation:"https://github.com/daymxn/kHTML",
        type: "Kotlin Library",
        date: "August 3rd, 2022",
        description: "Lightweight HTML5 DSL written in pure Kotlin. Write 1:1 HTML elements as Kotlin objects, and compile them to clean HTML5 markdown with properties.",
        stacks: [Kotlin, HTML5, Gradle]
    },
]

export default Projects;