import React from 'react';
import { Button, Grid, Typography, SvgIcon } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import  {Projects as MyProjects} from '../../Projects';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(() => ({
    mainContainer: {
        display: "flex", 
        height: "90%",
        overflowX: "clip",
        justifyContent: "center"
    },
    text: {
        color: "#fff",
        fontWeight: "400",
    },
    currentPage: {
        color: "#fff",
        fontWeight: "400",
        borderBottom: "2px solid #ccc"
    },
    card: {
        width: '275px'
    },
    title: {
        fontSize: 14
    }
}));

function Project(props){
    const classes = useStyles();
    const history = useHistory();

    const LearnMore = () => {
        history.push("/projects/view/" + props.learnLocation);
    }
    const Demo = () => {
        window.location.href = props.demoLocation;
    }
    const Source = () => {
        window.location.href = props.sourceLocation;
    }
    const Documentation = () => {
        window.location.href = props.documentationLocation;
    }

    return (
    <Card className={classes.card} variant="outlined">
        <CardContent style={{paddingBottom: '0rem', height: '230px'}}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                {props.type}
            </Typography>
            <Typography variant="h5">
                {props.name}
            </Typography>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                {props.date}
            </Typography>
            <Typography variant="body2" style={{paddingTop: '.5rem'}}>
                {props.description}
            </Typography>
            
        </CardContent>
        <CardContent>
        <Grid container>
                {
                    props.stacks.map((stack, index) => (
                        <Grid key={props.name + index} item>
                            <SvgIcon component={stack} style={{fontSize: 25, paddingRight: '.5rem'}} viewBox="0 0 128 128"/>
                        </Grid>
                    ))
                }
            </Grid>
        </CardContent>
        <CardActions style={{paddingTop:'0px', height:'30px'}}>
            {(!props.learnLocation && !props.demoLocation && !props.sourceLocation)?
            <Button color="primary" szie="small" disabled>No additional documentation</Button>
            :null}
            {(props.learnLocation)?<Button color="primary" size="small" onClick={LearnMore}>Learn More</Button>:null}
            {(props.documentationLocation)?<Button color="primary" size="small" onClick={Documentation}>Documentation</Button>:null}
            {(props.demoLocation)?<Button size="small" onClick={Demo}>Demo</Button>:null}
            {(props.sourceLocation)?<Button size="small" onClick={Source}>Source</Button>:null}
        </CardActions>
    </Card>
    )
}

function Content(){
    return (
        <Grid container style={{paddingTop: '1rem', justifyContent: "center", alignSelf: "center"}} spacing={1}>
            {MyProjects.map(project=> (
                <Grid key={project.name} item>
                    <Project {...project} />
                </Grid>
            ))}
            
        </Grid>
    )
}

export default function Projects(props){
    const classes = useStyles();
    return (
        <Grid container className={classes.mainContainer}>
            <Content pages={props.pages} />
        </Grid>
    )
}