import React from 'react';
import { Typography, Grid, SvgIcon } from '@material-ui/core';
import {EventNote as dateImage, Schedule as readImage} from '@material-ui/icons'

function IconWithText(props){
    return (
        <Grid container>
            <Grid item>
                <SvgIcon component={props.src} style={{fontSize: props.fontSize, color: '#fff', paddingRight: '.25rem'}}/>
            </Grid>
            <Grid item>
                <Typography style={{fontSize: props.fontSize}} color="textSecondary">
                    {props.children}
                </Typography>
            </Grid>
        </Grid>

    )
}

export default function MetaData(props){
    return (
        <Grid container style={{paddingBottom: '1rem'}}>
            <Grid item xs={12}>
                <IconWithText fontSize={'.8rem'} src={dateImage}>{props.date}</IconWithText>
            </Grid>
            <Grid item xs={12}>
                <IconWithText fontSize={'.8rem'} src={readImage}>{props.readTime}</IconWithText>
            </Grid>
        </Grid>
    )
}