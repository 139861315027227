import React from 'react';
import {Typography, Grid, Box} from '@material-ui/core';

export default function Header(props){
    return (
        <Grid container style={{paddingBottom: '.5rem'}}>
            <Grid item xs={12}>
                <Typography variant="h6" style={{color: 'white'}}>
                    {props.text}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box style={{border: '.1px solid white', width:'150%'}}></Box>
            </Grid>
        </Grid>
    )
}