import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import { useHistory } from "react-router-dom";

//Landing page

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    text: {
        color: "#fff",
        fontWeight: "400",
        textShadow: "0px 0px 20px black"
    }
}));

function MenuItem(props) {
    const history = useHistory();
    const openPage = () => {
        history.push('/' + props.location);
    }
    return (
        <Grid item xs={3}>
            <Zoom in={props.loaded} timeout={(props.shouldAnimate) ? 500 : 0} style={{ transitionDelay: (props.shouldAnimate) ? props.delay : 0 }}>
                <Button onClick={openPage} fullWidth>{props.name}</Button>
            </Zoom>
        </Grid>
    )
}

function Menu(props) {
    return (
        <Grid container>
            {(props.pages) ? props.pages.map((page, index) => (
                <MenuItem key={index} shouldAnimate={props.shouldAnimate} loaded={props.loaded} name={page.name} location={page.location} delay={(index * 500).toString() + 'ms'} />
            )) : null}
        </Grid>
    )
}

function Header(props) {
    const classes = useStyles();
    const [loaded, setLoaded] = React.useState(!props.shouldAnimate);
    const largerScreens = useMediaQuery('(min-width: 892px');
    React.useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
        }, props.delay)
    }, [loaded, props.delay])
    return (
        <Grid container style={{ height: "100%", maxHeight: '10rem', maxWidth: (largerScreens ? "56rem" : '40rem' )}}>
            <Grid item style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <Fade in={true} timeout={(props.shouldAnimate) ? props.delay : 0}>
                    <Typography className={classes.text} variant="h1">Daymon {(largerScreens ? 'Littrell-Reyes' : '')}</Typography>
                </Fade>
            </Grid>
            <Menu shouldAnimate={props.shouldAnimate} loaded={loaded} pages={props.pages} />
        </Grid>
    )
}

export default function Landing(props) {
    const [shouldAnimate] = React.useState(window.sessionStorage.getItem("firstLoad") === null);
    function checkShouldAnimate() {
        if (window.sessionStorage.getItem("firstLoad") === null) {
            window.sessionStorage.setItem("firstLoad", 1)
        }
    }

    React.useEffect(() => {
        checkShouldAnimate()
    }, [shouldAnimate])

    const classes = useStyles();
    return (
        <Grid container className={classes.mainContainer}>
            <Header shouldAnimate={shouldAnimate} delay={(shouldAnimate) ? 1500 : 0} pages={props.pages} />
        </Grid>
    )
}