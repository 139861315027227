import { Grid } from '@material-ui/core';
import React from 'react';
import Header from '../Util/header';
import BodyText from '../Util/bodytext';
import ImageText from '../Util/imagetext';
import MetaData from '../Util/metadata';

import homepng from '../../../Images/Screenshots/easynotes/home.png';
import loginpng from '../../../Images/Screenshots/easynotes/login.png';
import dashboardpng from '../../../Images/Screenshots/easynotes/dashboard.png';
import collectionspng from '../../../Images/Screenshots/easynotes/collections.png';
import tasklistpng from '../../../Images/Screenshots/easynotes/tasklist.png';
import memberspng from '../../../Images/Screenshots/easynotes/members.png';
import managepng from '../../../Images/Screenshots/easynotes/manage.png';
import editspng from '../../../Images/Screenshots/easynotes/edits.png';
import invitespng from '../../../Images/Screenshots/easynotes/invites.png';
import profilepng from '../../../Images/Screenshots/easynotes/profile.png';

function Content(){
    return (
        <Grid container style={{padding: '1rem'}}>
            <Grid item xs={12}>
                <MetaData date="November 12th, 2020" readTime="6 min read" />
            </Grid>
            <Grid item>
                <Header text="Introduction" />
            </Grid>
            <Grid item>
                <BodyText>
                    Recently I had an interview with LaunchCode- which is essentially a recruitment agency that aims to bridge talented software engineers (that lack a college degree) with potential jobs that they otherwise wouldn't have been able to get. The interview went splendidly, but they tasked me with a web application for my portfolio. See, my primary focus within the realm of development- is that of software development. So my portfolio at the time was predominately software - based. And with most of their job opportunities lying within the range of web - based applications, they wanted me to work on a basic CRUD application. With that in mind, I decided to work up a MEAN web application orientated around task management. EasyNotes ended up being just that - a task management system in the form of "Collections" and "Notes" aimed at smaller - medium sized businesses. 
                </BodyText>
            </Grid>
            <Grid item>
                <Header text="Body" />
            </Grid>
            <Grid item>
                <ImageText src={homepng}>
                    I decided to start with the front-end first, being as I was confident in my ability to not only craft the back-end, but be able to section it alongside the front-end. I already had a general idea of what I wanted in my head, it was just a matter of getting it on paper. And with the beauty of bootstrap and additional CSS:    
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={loginpng}>
                    With such a simple homepage, the only reasonable page(s) to follow suit would be none other than the login and register pages! I intended for the general theme (beyond the home page) to be a more simplistic, clean-cut, modular style. With that in mind, the authentication pages were put together.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={dashboardpng}>
                    With the dashboard being the next front-end goal post, I felt it best to switch to a more Angular style of development. After throwing together a rough draft of the back-end to get the website up and running for MVC, and hours of scrutinizing the aesthetic of the website as a whole, this is what I settled on:
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={collectionspng}>
                    We've got the website split up into three different sections; Collections, Invites, and Profile. I'll take you through each one individually. Collections would act as a page to not only access all your collections, but filter and create them as well.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={tasklistpng}>
                    When accessing any of your collections, your notes are presented to you in a priority-list format, where you can control the priority and title of any given note. A variety of moderation tools are also presented to you (if you have moderator or owner access to the collection).
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={memberspng}>
                    A must-have feature is a members list, so that was one of the first pages I incorporated and designed. With a user-friendly grid format, displaying each user's display name, profile photo, and role in the collection.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={managepng}>
                    A feature that is privy to those with moderation access to the collection is the user panel. The panel allows moderators to manage user's roles, and even kick them. Each role has a clear description, highlighting their permissions for the collection.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={editspng}>
                    Something that I'm fairly proud of is the edit history. Each collection has what's called an edit history; it keeps track of everything that's ever happened in the collection and presents it in chronological order from latest to oldest.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={invitespng}>
                    Moving on to the Invites page of the site, the page is literally what it sounds like- a portal for managing all collection invites that you as a user receive.
                </ImageText>
            </Grid>
            <Grid item>
                <ImageText src={profilepng}>
                    And the last page would be the Profile page. Here you can do basic things with your account, like; logout, change your display name or change your profile photo.
                </ImageText>
            </Grid>
            <Grid item>
                <BodyText>
                    From here, it was only a matter of finishing up the back-end connections with the front-end and adding additional security measures behind the scenes. At some point, I may do a back-end write-up for the process of making a MEAN or CRUD application, but with there being so many resources already on the topic I felt it best suitable to instead just showcase the features of the website itself.
                </BodyText>
            </Grid>
            <Grid item>
                <Header text="Conclusion" />
            </Grid>
            <Grid item>
                <BodyText>
                    All in all, I enjoyed this project. I spent hours just playing around with the front-end, learning more about the way Bootstrap and AngularJs work alongside one another. Although, the industry seems to now be steering more towards react (for now), so I ought to pick that up instead. You'll probably be seeing a MERN application from me soon!
                </BodyText>
            </Grid>
        </Grid>
        
    )
}

export default function EasyNotes(){
    return (
        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
            <Grid>
                <Content /> 
            </Grid>
        </Grid>
    )
}