import {React, useState} from 'react';
import BodyText from './bodytext';
import { Backdrop, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

export default function ImageText(props){
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const close = () => {
        setOpen(false);
    }
    const toggle = () => {
        setOpen(!open);
    }
    return (
        <Box>
            <Backdrop className={classes.backdrop} onClick={close} open={open}>
                <img alt="example" src={props.src} style={{maxWidth: '100%', paddingBottom: '1rem'}} />
            </Backdrop>
            <BodyText>
                {props.children}
            </BodyText>
            <img onClick={toggle} alt="example" src={props.src} style={{maxWidth: '100%', paddingBottom: '1rem'}} />
        </Box>
    )
}