import React from 'react';
import {Grid, Typography, Divider, Chip} from '@material-ui/core';
import LabeledOutline from "../Util/LabeledOutline";

function School(props){
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography color="textPrimary" variant="body1">{props.name}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" variant="body2">{props.degree}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" variant="body1">{props.date}</Typography>
            </Grid>
        </Grid>
    )
}

function Work(props){
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography color="textPrimary" variant="body1">{props.location}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" variant="body2">{props.position}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" variant="body1">{props.date}</Typography>
            </Grid>
        </Grid>
    )
}

function ExtraCurricular(){
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <School name="CodePath" degree="Cyber Security Course, 148.6% GPA" date="2021" />
            </Grid>
            <Grid item xs={12}>
                <School name="Software Engineering Tutor" degree="Metropolitan Community College-Kansas City" date="2018 - 2020" />
            </Grid>
            <Grid item xs={12}>
                <School name="Software Engineering Tutor" degree="Truman High School" date="2016 - 2018" />
            </Grid>
        </Grid>


    )
}  


function Education(){
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <School name="Western Governors University" degree="Bachelor of Engineering in Software Engineering, 4.0 GPA" date="2020 - 2022" />
            </Grid>
            <Grid item xs={12}>
                <School name="Metropolitan Community College-Kansas City" degree="Associate's Degree in Software Engineering, 3.5 GPA" date="2018 - 2020" />
            </Grid>
            <Grid item xs={12}>
                <School name="Truman High School" degree="Diploma, 3.5 GPA" date="2014 - 2018" />
            </Grid>
        </Grid>
    )
}

function WorkHistory() {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
                <Work location="Google" position="Software Engineer" date="Spring 2022 - Current" />
            </Grid>
            <Grid item xs={12} md={6}>
                <Work location="Google" position="Software Engineering Intern" date="Fall 2021 - Winter 2021" />
            </Grid>
            <Grid item xs={12} md={6}>
                <Work location="Asana" position="Software Engineering Intern" date="Winter 2021 - Spring 2022" />
            </Grid>
        </Grid>
    )
}

function History(){
    return (
        <Grid container>
            <Grid item xs={12} style={{padding: '.5rem'}}>
                <LabeledOutline label="Work History">
                    <WorkHistory />
                </LabeledOutline>
            </Grid>

            <Grid item xs={12} md={6} style={{padding: '.5rem'}}>
                <LabeledOutline label="Education">
                    <Education />
                </LabeledOutline>
            </Grid>
           
            <Grid item xs={12} md={6} style={{padding: '.5rem'}}>
                <LabeledOutline label="Extracurricular">
                    <ExtraCurricular />
                </LabeledOutline>
            </Grid>
        </Grid>
    )
}

function Skills(){
    const skillSet = [
        "Kotlin",
        "C",
        "Python",
        "ASM",
        "Bash",
        "Scala",
        "C++",
        "C#",
        "Java",
        "JavaScript",
        ".NET",
        "React",
        "Node.js",
        "Lua",
        "Luau",
        "MongoDB",
        "MySQL",
        "Maven",
        "Google Cloud Platform", 
        "Amazon Web Services"
    ]
    return (
        <Grid container spacing={1} style={{paddingLeft: '.5rem', paddingRight: '.5rem'}}>
            {skillSet.map(skill=>(
                <Grid item key={skill}>
                    <Chip size="small" label={skill} />
                </Grid>
            ))}
        </Grid>
    )
}

function Content(){
    return (
        <Grid container style={{padding: '1rem'}}>
            <Grid item xs={12}>
                <Typography color="textPrimary" variant="h4">Daymon Littrell-Reyes</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" variant="h6">Software Engineer</Typography>
            </Grid>
            <Grid item xs={6} style={{padding: '1rem'}}>
                <Divider />
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <History />
                </Grid>
                <Grid item xs={12}>
                    <Skills />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default function About(){
    return (
        <Content />
    )
}